.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.disableScrollBar::-webkit-scrollbar {
  display: none;
}

.scrollArea::-webkit-scrollbar {
  width: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.scrollArea::-webkit-scrollbar-thumb {
  border: 3.5px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-clip: padding-box;
  background-color: #d5d5d6;  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #000000;  
}

div, p, button, label, input, textarea {
  font-family: Maven Pro;
  -webkit-tap-highlight-color: transparent;
}

button {
  cursor: pointer;
  border: none;
  -webkit-tap-highlight-color: transparent;
}

button:active {
  transform: scale(0.95);
}

input:focus, textarea:focus, select:focus {
  outline: none;  
  border-bottom: 1px solid #00A7A9;
}

input[type="radio"], input[type="checkbox"] {
  accent-color: #00A7A9;
  border: 1px solid #00A7A9;
}

.selector::-webkit-scrollbar {
  width: 10px;
}

.selector::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #d5d5d6;
}

.disableScrollBar::-webkit-scrollbar {
  display: none;
}
